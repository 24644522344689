<template>
    <div class="nvx-button-view-container">
        <div>
            <slot name="content"></slot>
        </div>
        <div style="padding-bottom: 0.5em;">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "NvxButtonView",

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";

.nvx-button-view-container {
    display: flex; 
    flex-direction: column; 
    height: 100%; 
    justify-content: space-between;
}

</style>