<template>
  <div class="nvx-app-container">
    <nvx-button-view>

      <template v-slot:content>
        <router-link to="/account_email_update">
          <chevron :iconDirection="'left'" />
        </router-link>

        <h2 class="sdc-title"> {{ $t('email_account_title') }} </h2>

        <nvx-section>
          <h3>
            {{ $t('email_account_subtitle', { userEmail: getUserName }) }}
          </h3>

          <p class="sdc-description">
            {{ $t('email_account_text') }}
            <router-link to="/account_email_update">
              {{ $t('email_account_change_link') }}
            </router-link>
          </p>
        </nvx-section>
      </template>

      <template v-slot:buttons>
        <button class="sdc-button" @click="exit()" block variant="primary">
          {{ $t('email_account_understand') }}
        </button>
        <button v-if="!isEmailSend" class="sdc-button-secondary" @click="sendEmail()" block variant="primary">
          {{ $t('email_account_resend') }}
        </button>
        <button v-else class="sdc-button-secondary" block variant="primary">
          {{ $t('email_account_isresend') }}
        </button>
      </template>

    </nvx-button-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Chevron from '../svg/chevron.vue';
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxButtonView from "@/components/ui/NvxButtonView.vue";

export default {
  components: {
    Chevron,
    NvxSection,
    NvxButtonView
  },
  name: 'AccountEmail',

  data() {
    return {
      isEmailSend: false,
    }
  },

  methods: {
    ...mapActions("users", ["sendEmailConfirmation", "getUserData", "fetchUserData"]),
    ...mapActions('users', ['setToken']),

    exit() {
      window.location.href = '/account/';
    },

    async sendEmail() {
      let sendEmail_param = { email_confirmation_send: true };
      let response = await this.sendEmailConfirmation(sendEmail_param);
      if (response.status == 200) {

        this.isEmailSend = true
      }
    }
  },

  computed: {
    ...mapGetters("users", ["getUserName"]),
  },

  async mounted() {
    let parameters = this.$route.query;
    if (parameters['token']) {
      let token = parameters['token'];
      this.setToken(token);
    }
    this.fetchUserData();
    window.zE('webWidget', 'hide');
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";
</style>